import React, { useState } from 'react';
import { Box, Button, Textarea, Input, Flex, Text } from '@chakra-ui/react';

type DraftEditorProps = {
    onDraftFinish: ({
        draft,
        references
    }) => void;
    backToInit: () => void;
}

const DraftEditor: React.FC<DraftEditorProps> = ({ onDraftFinish, backToInit }) => {
    const [draftContent, setDraftContent] = useState<string>('');
    const [fileName, setFileName] = useState<string | null>(null);
    const [referenceContent, setReferenceContent] = useState<string>('');
    const [referenceName, setReferenceName] = useState<string | null>(null);
    const [moduleStep, setModuleStep] = useState<number>(0);

    const handleDraftUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    setDraftContent(content);
                }
            };
            reader.readAsText(file);
        } else {
            alert('Please upload a valid .txt file');
        }
    };

    const handleReferenceUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setReferenceName(file.name);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    setReferenceContent(content);
                }
            };
            reader.readAsText(file);
        } else {
            alert('Please upload a valid .txt file');
        }
    };

    const goPrevious = () => {
        if (moduleStep !== 1) {
            setModuleStep(moduleStep - 1);
        } 

        if (moduleStep === 0) {
            backToInit();
        }
    }

    const goNext = () => {
        setModuleStep(moduleStep + 1);
    }

    const draftOnFinish = () => {
        console.log('Draft on finish and start to write paper');
        onDraftFinish({
            draft: draftContent,
            references: referenceContent
        });
    }

    return (
        <Box p={5} w={'full'} h={'full'}>
            {moduleStep === 0 &&
                <Box>
                    <Text my={5}>Please upload your writing draft here...</Text>
                    <Input
                        type="file"
                        accept=".txt"
                        onChange={handleDraftUpload}
                        mb={4}
                    />
                    {fileName && <Button mb={2} w={'full'}>{fileName}</Button>}
                    <Textarea
                        value={draftContent}
                        onChange={(e) => setDraftContent(e.target.value)}
                        placeholder="File content will appear here"
                        height="500px"
                        resize="none"
                    />
                </Box>
            }

            {moduleStep === 1 &&
                <Box>
                    <Box>
                        <Text my={5}>Please upload if any reference articles list here, like .bib file...</Text>
                        <Input
                            type="file"
                            accept=".txt"
                            onChange={handleReferenceUpload}
                            mb={4}
                        />
                        {referenceName && <Button mb={2} w={'full'}>{referenceName}</Button>}
                        <Textarea
                            value={referenceContent}
                            onChange={(e) => setReferenceContent(e.target.value)}
                            placeholder="Reference content will appear here"
                            height="500px"
                            resize="none"
                        />
                    </Box>
                </Box>
            }

            <Flex flexDir={'row'} justifyContent={'flex-end'} mt={5}>
                <Button mr={2} onClick={goPrevious}>Previous</Button>
                { moduleStep !== 1 &&
                    <Button colorScheme='brand' onClick={goNext}>Next</Button>
                }
                { moduleStep === 1 &&
                    <Button colorScheme='brand' onClick={draftOnFinish}>Done</Button>
                }
            </Flex>
        </Box>
    );
};

export default DraftEditor;
