// Writer.tsx
import { Box, Flex, Text, Wrap, WrapItem, Heading, Modal, Image, useDisclosure, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast, Card, CardBody, Center } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import OutlineExtractor from '../components/OutlineExtractor/OutlineExtractor';
import OutlineTemplateCard from '../components/OutlineTemplateCard/OutlineTempalteCard';
// import OutlineNodesDisplay from '../components/OutlineNodesDisplay/OutlineNodesDisplay';
import PaperCard from '../components/PaperCard/PaperCard';
import { useNavigate } from 'react-router-dom';
import {
    // createOutlineRequest, 
    // extractedOutlineResponse, 
    Outline
} from '../types/Writer/Outline';
import { OutlineService } from '../services';
import { ClientError } from '../utils/clientError';
import { PaperService } from '../services/paperService';
import { useAuth0 } from "@auth0/auth0-react";
import { Paper } from '../types/Writer/Paper';
import { EditIcon } from '@chakra-ui/icons';
// import { BiPlus } from 'react-icons/bi';
import DraftEditor from '../components/PaperEditorComponents/DraftEditor';
import NoDraftEditor from '../components/PaperEditorComponents/NoDraftEditor';
import SideNav from '../components/NavBar/SideNav';
// import { OutlineNode } from '../types/Writer/OutlineNode';


const Writer: React.FC = () => {
    const { user } = useAuth0();
    const { isOpen: _isOutlineOpen, onOpen: onOutlineOpen, onClose: _onOutlineClose } = useDisclosure();
    const { isOpen: isPaperViewerOpen, onOpen: onPaperViewerOpen, onClose: onPaperViewerClose } = useDisclosure();
    const navigate = useNavigate();
    const [outlines, setOutlines] = useState<Outline[]>([]);
    const [papers, setPapers] = useState<Paper[]>([]);
    const [_selectedOutline, setSelectedOutline] = useState<Outline>(null);
    const [selectedPaper, setSelectedPaper] = useState<Paper>(null);
    const toast = useToast();
    // const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [_isExtractingOutline, _setIsExtractingOutline] = useState<boolean>(false);
    const { isOpen: isNewDocumentOpen, onOpen: onNewDocumentOpen, onClose: onNewDocumentClose } = useDisclosure();
    const [newDocumentStep, setNewDocumentStep] = useState<string>('init');

    useEffect(() => {
        async function getOutlines() {
            try {
                const outlines = await OutlineService.getOutlines();
                setOutlines(outlines);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        getOutlines();
    }, [])

    useEffect(() => {
        async function getPapers() {
            try {
                const papers = await PaperService.getPapers();
                setPapers(papers);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        getPapers();
    }, [])

    const templateCardOnClick = (outline: Outline) => {
        setSelectedOutline(outline);
        onOutlineOpen();
    }

    const useTemplate = async (outline: Outline, draft?: string, resources?: string) => {
        try {
            const newPaper = await PaperService.createPaper({
                name: 'Untitled',
                authors: user.user_metadata.first_name && user.user_metadata.last_name ? `${user.user_metadata.first_name} ${user.user_metadata.last_name}` : user.name,
                outlineId: outline.id,
                abstract: 'The abstract is a concise summary of your entire paper, providing a clear overview of your research problem, methodology, key findings, and conclusions. It typically consists of 150–250 words and should allow the reader to understand the essence of your research without needing to read the entire paper.',
                keywords: 'The keywords section lists the most important terms related to your research that help others find your paper through search engines or databases. Typically, you will include 4–6 keywords that are directly related to the core content of your research.',
                draft: draft ? draft : '',
                resources: resources ? resources : ''
            });
            navigate(`/editor/${newPaper.id}`);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    const continueEditing = (paper_id) => {
        console.log('continue to editing ', paper_id);
        navigate(`/editor/${paper_id}`);
    }

    const deletePaper = async (paperId) => {
        const originalPapers = papers;
        try {
            const newPapers = papers.filter(paper => paper.id !== paperId);
            setPapers(newPapers);
            await PaperService.deletePaper(paperId).then(
                res => {
                    console.log(res)
                }
            )
            toast({
                title: `Ppaer has been deleted.`,
                description: ``,
                status: "success",
                duration: 3000,
            })
        } catch (error) {
            new ClientError(error).toast();
            setPapers(originalPapers);
        }
    }

    const deleteOutline = async (outlineId) => {
        const originalOutlines = outlines;

        try {
            const newOutlines = outlines.filter(outline => outline.id !== outlineId);
            setOutlines(newOutlines);

            await OutlineService.deleteOutline(outlineId);
        } catch (error) {
            new ClientError(error).toast();
            setOutlines(originalOutlines);
        }
    }

    const viewPaper = async (paperId: string) => {
        const filteredPaper = papers.find(paper => paper.id === paperId);
        setSelectedPaper(filteredPaper);
        onPaperViewerOpen();
    }

    // const handleFilesChange = (files: File[]) => {
    //     setUploadedFiles(files);
    // };

    // const extractOutline = () => {
    //     setIsExtractingOutline(true);
    //     OutlineService.extractOutline(uploadedFiles[0]).then(
    //         (outlineNodes: extractedOutlineResponse[]) => {
    //             setIsExtractingOutline(false);
    //             setUploadedFiles([]);
    //             console.log(outlineNodes)
    //             // const tmpOutline: createOutlineRequest = {
    //             //     name: 'Default outline',
    //             //     nodes: []
    //             // }
    //             // outlineNodes.forEach(node => {
    //             //     if (node.subsections.length == 0) {
    //             //         tmpOutline.nodes.push({
    //             //             id: 
    //             //         })
    //             //     }
    //             // })
    //         }
    //     )
    // }

    const newDocumentOnClick = () => {
        onNewDocumentOpen();
        setNewDocumentStep('init');
    }

    const onDraftFinish = ({ draft, references}):void => {
        useTemplate(outlines[0], draft, references);
    }

    return (
        <Flex flexDir={'row'} w={'full'} h={'full'}>
            <SideNav />
            <Flex flexDir={'column'} h={'full'} p={5} overflow={'auto'}>
                <Box bg={'gray.100'} p={5} borderRadius={'5px'}>
                    <Heading size={'md'} mb={3}>Start a new paper</Heading>
                    <Wrap mt={5} overflow={'auto'} w={'full'} >
                        <WrapItem>
                            <Flex flexDir={'column'}>
                                <Card w={'200px'} h={'260px'} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #ccc' }} border={'1px solid #ccc'}>
                                    <CardBody onClick={newDocumentOnClick}>
                                        <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                            <Box>
                                                <Image
                                                    w={'35px'}
                                                    objectFit='cover'
                                                    src='/doenba-ico-blue.png'
                                                    alt='Doenba'
                                                />
                                            </Box>
                                        </Center>
                                    </CardBody>
                                </Card>
                                <Box mt={2} w={'full'} textAlign={'center'}>
                                    <Text fontSize={14}>
                                        Create
                                    </Text>
                                </Box>
                            </Flex>
                        </WrapItem>
                        {outlines.map(outline => (
                            <WrapItem key={outline.id}>
                                <OutlineTemplateCard
                                    outline={outline}
                                    deleteOutline={() => deleteOutline(outline.id)}
                                    showDetail={() => templateCardOnClick(outline)}
                                    onUseTemplate={() => useTemplate(outline)}
                                />
                            </WrapItem>
                        ))}
                    </Wrap>
                </Box>

                <Box mt={5} bg={'gray.100'} p={5} borderRadius={'5px'} pb={12}>
                    <Heading size={'md'} pb={3}>Recent Papers ({papers.length})</Heading>
                    <Wrap mt={5} w={'full'} overflow={'auto'} h={'full'}>
                        {papers.map(paper => (
                            <WrapItem key={paper.id}>
                                <PaperCard
                                    paper={paper}
                                    viewPaper={() => viewPaper(paper.id)}
                                    continueEditing={() => continueEditing(paper.id)}
                                    discardPaper={() => deletePaper(paper.id)}
                                />
                            </WrapItem>
                        ))}
                    </Wrap>
                </Box>
            </Flex>
            {/* <Flex flexDir={'row'} p={5} h={'95%'} overflow={'auto'}>
                <Box w={'50%'} flex={'1 auto'} px={5}>
                    <Center w={'full'} h={'full'}>
                        <Flex flexDir={'column'} justifyContent={'flex-start'} w={'full'} h={'full'}>
                            <Box h={'200px'} mb={12}>
                                <Heading size={'md'} mb={3}>Outline Extractor</Heading>
                                <OutlineExtractor onFilesChange={handleFilesChange} isExtractingOutline={isExtractingOutline} />
                                {uploadedFiles.length > 0 &&
                                    <Button mt={2} w={'full'} size={'sm'} colorScheme='yellow' onClick={extractOutline}>Extract outline</Button>
                                }
                            </Box>
                            <Box mt={5} overflow={'hidden'}>
                                <Heading size={'md'} mb={3}>Recent outline template I uploaded ({outlines.length})</Heading>
                                <Wrap h={'calc(100vh - 200px)'} overflow={'auto'}>
                                    {outlines.map(outline => (
                                        <WrapItem key={outline.id}>
                                            <OutlineTemplateCard
                                                outline={outline}
                                                deleteOutline={() => deleteOutline(outline.id)}
                                                showDetail={() => templateCardOnClick(outline)}
                                                onUseTemplate={() => useTemplate(outline)}
                                            />
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </Box>
                        </Flex>
                    </Center>
                </Box>
                <Box w={'50%'} >
                    <Heading size={'md'} pb={3}>My Papers ({papers.length})</Heading>
                    <Wrap w={'full'} overflow={'auto'} h={'90vh'}>
                        {papers.map(paper => (
                            <WrapItem w={'full'} key={paper.id}>
                                <PaperCard
                                    paper={paper}
                                    viewPaper={() => viewPaper(paper.id)}
                                    continueEditing={() => continueEditing(paper.id)}
                                    discardPaper={() => deletePaper(paper.id)}
                                />
                            </WrapItem>
                        ))}
                    </Wrap>
                </Box>
            </Flex> */}

            {/* {selectedOutline &&
                <Modal onClose={onOutlineClose} size={'xl'} isOpen={isOutlineOpen} scrollBehavior={'inside'}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{selectedOutline.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <OutlineNodesDisplay data={selectedOutline.nodes} />
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={3} colorScheme='yellow'>Start writing your paper based on this outline</Button>
                            <Button onClick={onOutlineClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            } */}

            <Modal isOpen={isNewDocumentOpen} onClose={onNewDocumentClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent minW={'800px'}>
                    <ModalHeader>New Paper</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {newDocumentStep === 'init' &&
                            <Flex flexDir={'row'} w={'full'}>
                                <Center boxShadow={'md'} mr={2} w={'50%'} cursor={'pointer'} h={'200px'} onClick={() => setNewDocumentStep('hasDraft')}>
                                    I already have a draft
                                </Center>
                                <Center boxShadow={'md'} w={'50%'} cursor={'pointer'} h={'200px'} onClick={() => setNewDocumentStep('noDraft')}>
                                    I do not have a draft yet
                                </Center>
                            </Flex>
                        }
                        {newDocumentStep === 'hasDraft' &&
                            <DraftEditor onDraftFinish={onDraftFinish} backToInit={() => setNewDocumentStep('init')} />
                        }
                        {newDocumentStep === 'noDraft' &&
                            <NoDraftEditor onDraftFinish={() => useTemplate(outlines[0])} backToInit={() => setNewDocumentStep('init')} />
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>

            {selectedPaper && <Modal isOpen={isPaperViewerOpen} onClose={onPaperViewerClose} size={'full'}>
                <ModalOverlay />
                <ModalContent w={'90%'} h={'90vh'}>
                    <ModalHeader>{selectedPaper.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflow={'auto'}>
                        {JSON.stringify(selectedPaper.content)}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='yellow' size={'sm'} leftIcon={<EditIcon />} mr={3} onClick={() => continueEditing(selectedPaper.id)}>
                            Edit
                        </Button>
                        {/* <Button variant='ghost' size={'sm'} onClick={() => deletePaper(selectedPaper.id)}>Discard</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>}
        </Flex>
    );
};

export default Writer;
