import React, { useState, useRef } from 'react';
import { Box, 
    Editable,
  EditableTextarea,
  EditablePreview,
 } from '@chakra-ui/react';
import { useOutsideClick } from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import 'katex/dist/katex.min.css';
// import { GrAggregate } from 'react-icons/gr';
// import { FaRegLightbulb } from 'react-icons/fa';
import { BlockTypeSelect, BoldItalicUnderlineToggles, 
  toolbarPlugin, UndoRedo, codeBlockPlugin, 
  CodeToggle, CreateLink, 
  codeMirrorPlugin, InsertCodeBlock, 
  InsertImage, 
  InsertTable, InsertThematicBreak, ListsToggle, MDXEditor, 
  // ChangeAdmonitionType, 
  // ChangeCodeMirrorLanguage, 
  // DiffSourceToggleWrapper, InsertAdmonition, 
  // InsertFrontmatter, 
  // InsertSandpack, 
  // quotePlugin, ShowSandpackInfo, 
  // sandpackPlugin, ConditionalContents, 
  tablePlugin, listsPlugin } from '@mdxeditor/editor'
  import '@mdxeditor/editor/style.css'

interface EditableTextProps {
    nodeContent: string;
    updatePaperNodeContent: (value: string) => void;
    handleInputFocus: () => void;
    savePaper: () => void;
    // selectedContextRephrase: (context: string) => void;
}

const ParagraphEditor: React.FC<EditableTextProps> = ({ nodeContent, updatePaperNodeContent }) => {
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLDivElement | null>(null);
  const [articleContent, setArticleContent] = useState<string>(nodeContent ? nodeContent : '');

    console.log(nodeContent)

  useOutsideClick({
    ref: ref,
    handler: () => { 
        setIsEditing(false);
        updatePaperNodeContent(articleContent);
        // savePaper();
    }
  });

  return (
    <Box
      ref={ref}
      onDoubleClick={() => setIsEditing(true)}>
        <Editable defaultValue={`123 we have we have we have we have {"\\(\\frac{10}{4x} \\approx 2^{12}\\)"} we have we have we have we have`}>
            <MathJax>
                <EditablePreview />
            </MathJax>
            <EditableTextarea />
        </Editable>
      {isEditing ? (
        <Box ref={textareaRef} w={'full'}>
        <MDXEditor 
          markdown={articleContent}
          plugins={[
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  {' '}
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <BlockTypeSelect />
                  {/* <ChangeAdmonitionType/> */}
                  {/* <ChangeCodeMirrorLanguage/> */}
                  <CodeToggle/>
                  <CreateLink/>
                  {/* <InsertAdmonition/> */}
                  <InsertCodeBlock/>
                  {/* <InsertFrontmatter/> */}
                  <InsertImage/>
                  {/* <InsertSandpack/> */}
                   <InsertTable/>
                  <InsertThematicBreak/>
                  <ListsToggle/>
                  {/* <IoDiamondOutline /> */}
                  {/* <ConditionalContents
                    options={[
                        { when: (editor) => editor?.editorType === 'codeblock', contents: () => <ChangeCodeMirrorLanguage /> },
                        { when: (editor) => editor?.editorType === 'sandpack', contents: () => <ShowSandpackInfo /> },
                        { fallback: () => ( <> 
                        <InsertCodeBlock />
                        <InsertSandpack />
                      </>) }
                      ]}
                    /> */}
                </>
              )
            }),
            codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
            codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } }),
            tablePlugin(),
            listsPlugin()
          ]}
          autoFocus
          onChange={setArticleContent} 
          contentEditableClassName='prose'
        />
{/* 
      {showMenu && selectedText && (
        <Box
          position="absolute"
          top={`${menuPosition.y}px`}
          left={`${menuPosition.x}px`}
          bg="white"
          boxShadow="md"
          p={2}
          borderRadius="md"
          zIndex={10}
        >
          <HStack spacing='5px'>
            <Tooltip label='Rephraser'>
              <IconButton size={'xs'} aria-label='Rephraser' icon={<IoDiamondOutline />} onClick={() => {
                setShowMenu(false)
                selectedContextRephrase(selectedText)
              }} />
            </Tooltip>
            <Tooltip label='Quote and Q&A'>
              <IconButton size={'xs'} aria-label='Q&A' icon={<BsChatLeftQuote />} onClick={() => setShowMenu(false)} />
            </Tooltip>
          </HStack>
        </Box>
      )} */}
    </Box>
      ) : (
        <Box>
            <MathJax>
            {`${articleContent}`}
            </MathJax>
        </Box>
      )}
    </Box>
  );
};

export default ParagraphEditor;
