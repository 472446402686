import { useMemo } from "react";
import { Box, CloseButton, Flex, IconButton, 
	// Image, 
	Text } from "@chakra-ui/react";
import { 
	Sentences, 
	// SentenceType 
} from "../../types/Sentence";
import { FaQuoteLeft } from "react-icons/fa";
// import LatexSupportedText from "../LatexSupportedText";
// import ConversationMarkdown from "./ConversationMarkdown";
import useHighlights, { HighlightType } from "../../hooks/useHighlights";

interface Props {
	quoteSentenceIndexes: number[];
	clearQuoteSentenceIndexes: () => void;
	articleSentences: Sentences;
}

const QuoteSentences = ({
	articleSentences,
	quoteSentenceIndexes,
	clearQuoteSentenceIndexes,
}: Props) => {
    const { clearHighlights } = useHighlights();

	function handleCancelClick() {
		clearQuoteSentenceIndexes();
		clearHighlights(HighlightType.Quotation);
		const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
	}

	const quoteSentences = useMemo(() => {
		return quoteSentenceIndexes.map(
			// (sentenceIndex) => articleSentences[sentenceIndex]?.content
			(sentenceIndex) => articleSentences[sentenceIndex]
		);
	}, [quoteSentenceIndexes, articleSentences]);

	return (
		<>
			{quoteSentences.length > 0 &&
				<Flex flexDir={'row'} bg='gray.100' p={2} borderRadius={5} justifyContent={'space-between'} alignItems={'flex-start'}>
					<Flex flexDir={'row'} justifyContent={'flex-start'} alignContent={'center'}>
						<Box mr={2}>
							<FaQuoteLeft color="gray" width={'15px'} />
						</Box>
						<Text
							fontSize={'sm'}
							fontStyle={'italic'}
							color={'gray.500'}
							display="-webkit-box"
							overflow="hidden"
							textOverflow="ellipsis"
							style={{
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 1,
							}}
						>
							Please review your quotation from the article.
						</Text>
						{/* {quoteSentences.map(q => (
							<>
								{q.type == SentenceType.EQUATION && q.img &&
									<Image h={'25px'} src={
										`data:image/png;base64, ${q.img}`
									} alt='equation' />
								}
								{q.type == SentenceType.EQUATION && !q.img &&
									<Text
										fontSize={'sm'}
										fontStyle={'italic'}
										color={'gray.500'}
										display="-webkit-box"
										overflow="hidden"
										textOverflow="ellipsis"
										style={{
											WebkitBoxOrient: "vertical",
											WebkitLineClamp: 3,
										}}
									>
										<ConversationMarkdown content={q.content} />
									</Text>
								}
								{q.type !== SentenceType.EQUATION && <Text
									fontSize={'sm'}
									fontStyle={'italic'}
									color={'gray.500'}
									display="-webkit-box"
									overflow="hidden"
									textOverflow="ellipsis"
									style={{
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 1,
									}}
								>
									<LatexSupportedText>
										{q.content}
									</LatexSupportedText>
								</Text>}
							</>
						))} */}
					</Flex>
					<IconButton size={'xs'} aria-label='Search database' icon={<CloseButton />} onClick={handleCancelClick} />
				</Flex>
			}
		</>
	);
};

export default QuoteSentences;
