import React, { useState } from 'react';
import { Box, Button, Text, Input, Flex, Checkbox, VStack, IconButton, FormControl, FormLabel, Textarea, HStack } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FiMove } from 'react-icons/fi';

interface ListItem {
    id: string;
    content: string;
}

type NoDraftEditorProps = {
    onDraftFinish: () => void;
    backToInit: () => void;
}


const NoDraftEditor: React.FC<NoDraftEditorProps> = ({ backToInit, onDraftFinish }) => {
    const [_referenceContent, setReferenceContent] = useState<string>('');
    const [referenceName, setReferenceName] = useState<string | null>(null);
const [moduleStep, setModuleStep] = useState<number>(0);
    const [structureChecked, setStructureChecked] = useState(false);
    const [toneChecked, setToneChecked] = useState(false);
    const [title, setTitle] = useState<string>('');
    const [topic, setTopic] = useState<string>('');

    const [items, setItems] = useState<ListItem[]>([
        { id: '1', content: 'Introduction' },
        { id: '2', content: 'Abstract' },
        { id: '3', content: 'Methodology' },
    ]);

    const handleReferenceUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setReferenceName(file.name);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    setReferenceContent(content);
                }
            };
            reader.readAsText(file);
        } else {
            alert('Please upload a valid .txt file');
        }
    };

    const handleStructureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStructureChecked(e.target.checked);
    };

    const handleToneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setToneChecked(e.target.checked);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleTopicChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTopic(e.target.value);
    };

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        setItems(newItems);
    };

    const handleInputChange = (id: string, value: string) => {
        setItems(prevItems =>
            prevItems.map(item => (item.id === id ? { ...item, content: value } : item))
        );
    };


    const goPrevious = () => {
        if (moduleStep !== 1) {
            setModuleStep(moduleStep - 1);
        }
        if (moduleStep === 0) {
            backToInit();
        }
    }

    const goNext = () => {
        setModuleStep(moduleStep + 1);
    }

    const draftOnFinish = () => {
        console.log('Draft on finish and start to write paper');
        onDraftFinish();
    }

    return (
        <Box p={5} w='full' h={'full'}>
            {moduleStep === 0 &&
                <Flex flexDir={'column'}>
                    <Text my={5}>Please upload an artilce here for your writing reference</Text>
                    <Box>
                        <Input
                            type="file"
                            accept=".txt"
                            onChange={handleReferenceUpload}
                            mb={4}
                        />
                        {referenceName && <Button mb={2} w={'full'}>{referenceName}</Button>}
                    </Box>
                    <Flex flexDir={'column'} ml={5}>
                        <Box>
                            <Text>What elements to this article would you like to reference into your writing?</Text>
                        </Box>
                        <Box p={4} borderWidth="1px" borderRadius="md" w="300px">
                            <VStack align="start">
                                <Checkbox isChecked={structureChecked} onChange={handleStructureChange}>
                                    Structure
                                </Checkbox>
                                <Checkbox isChecked={toneChecked} onChange={handleToneChange}>
                                    Tone
                                </Checkbox>
                            </VStack>
                        </Box>
                    </Flex>
                </Flex>
            }

            {moduleStep === 1 &&
                <VStack spacing={4} align="start">
                    <Text my={5}>Tell us more to generate the best results</Text>
                    <FormControl>
                        <FormLabel>What is your article title?</FormLabel>
                        <Input
                            type="text"
                            value={title}
                            onChange={handleTitleChange}
                            placeholder="Enter your article title"
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>What is your writing topic?</FormLabel>
                        <Textarea
                            value={topic}
                            onChange={handleTopicChange}
                            placeholder="Enter your writing topic"
                        />
                    </FormControl>
                </VStack>
            }

            {moduleStep === 2 &&
                <Box>
                    <Text my={5}>We create a structure for you, you can edit it here</Text>
                    <Flex flexDir={'column'}>
                        <Box>Topic: {topic}</Box>
                        <Box mt={5}>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="inputs">
                                    {(provided) => (
                                        <Box {...provided.droppableProps} ref={provided.innerRef}>
                                            {items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <HStack
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            mb={3}
                                                            p={2}
                                                            borderWidth="1px"
                                                            borderRadius="md"
                                                            shadow="sm"
                                                        >
                                                            <IconButton
                                                                icon={<FiMove />}
                                                                aria-label="Drag"
                                                                {...provided.dragHandleProps}
                                                                variant="ghost"
                                                            />
                                                            <Input
                                                                value={item.content}
                                                                onChange={(e) => handleInputChange(item.id, e.target.value)}
                                                                placeholder="Enter text"
                                                                flex="1"
                                                            />
                                                        </HStack>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Box>
                    </Flex>
                </Box>
            }

            <Flex flexDir={'row'} justifyContent={'flex-end'} mt={5}>
                <Button mr={2} onClick={goPrevious}>Previous</Button>
                {moduleStep !== 2 &&
                    <Button colorScheme='yellow' onClick={goNext}>Next</Button>
                }
                {moduleStep === 2 &&
                    <Button colorScheme='yellow' onClick={draftOnFinish}>Done</Button>
                }
            </Flex>
        </Box>
    );
};

export default NoDraftEditor;
