import React, { useState, useRef, useEffect } from "react";
import { Box, Button, HStack, Input, VStack, Spinner, Tooltip } from "@chakra-ui/react";
import { WriterConversationMessage } from "../../types/Writer/Conversation";
import { WriterConversationService } from "../../services/writerConversationService";
import { ClientError } from "../../utils/clientError";
import PaperEditorConversationMessage from "./PaperEditorConversationMessage";
import { OutlineNode } from "../../types/Writer/OutlineNode";
import { getOutlineNodeById } from "../../utils/outlineNodeExtractor";

interface PaperEditorToolPanelProps {
	paperId: string;
	outlineNodes: OutlineNode[];
	selectedNodeId: number | null;
	setSelectedNodeId: (nodeId: number) => void;
}

enum TempMessageId {
	TEMP_REQUEST = -1,
	TEMP_RESPONSE = -2,
}

const PaperEditorConversationPanel: React.FC<PaperEditorToolPanelProps> = ({
	selectedNodeId,
	paperId,
	outlineNodes,
	setSelectedNodeId,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [writerConversationMessages, setWriterConversationMessages] = useState<
		WriterConversationMessage[]
	>([]);

	useEffect(() => {
		async function fetchWriterConversationMessages() {
			try {
				setWriterConversationMessages([]);
				setIsLoading(true);

				const response =
					await WriterConversationService.fetchWriterConversationMessagesForPaper(
						paperId
					);

				setWriterConversationMessages(response);
			} catch (error) {
				new ClientError(error).toast();
			} finally {
				setIsLoading(false);
			}
		}

		if (!paperId) {
			return;
		}

		fetchWriterConversationMessages();
	}, [paperId]);

	// Create a ref for the end of the messages container
	const messagesEndRef = useRef<HTMLDivElement | null>(null);

	const handlePartialBotResponse = (partialResponse: string) => {
		try {
			setWriterConversationMessages((prevMessages) => {
				const existingNewMessage = prevMessages.find(
					(msg) => msg.id === TempMessageId.TEMP_RESPONSE
				);

				if (existingNewMessage) {
					return prevMessages.map((msg) => {
						if (msg.id === TempMessageId.TEMP_RESPONSE) {
							return {
								...msg,
								message: msg.message + partialResponse,
							};
						}
						return msg;
					});
				} else {
					const newMessage: WriterConversationMessage = {
						id: TempMessageId.TEMP_RESPONSE,
						nodeId: selectedNodeId,
						paperId: paperId,
						message: partialResponse,
						sender: "bot",
					};

					return [...prevMessages, newMessage];
				}
			});
		} catch (error) {
			new ClientError(error).toast();
		}
	};

	function handleWriterConversationMessagesResponse(
		conversationPieces: WriterConversationMessage[]
	) {
		setWriterConversationMessages([...writerConversationMessages, ...conversationPieces]);
	}

	const handleSendMessage = async () => {
		if (inputValue.trim() === "") return;

		setInputValue("");

		try {
			const tempMessage: WriterConversationMessage = {
				id: TempMessageId.TEMP_REQUEST,
				paperId: paperId,
				nodeId: selectedNodeId,
				sender: "user",
				message: inputValue,
			};

			setWriterConversationMessages([...writerConversationMessages, tempMessage]);

			await WriterConversationService.addToWriterConversation(
				paperId,
				selectedNodeId,
				inputValue,
				handlePartialBotResponse,
				handleWriterConversationMessagesResponse
			);
		} catch (error) {
			new ClientError(error).toast();
		}
	};

	// Scroll to the bottom of the chat whenever a new message is added
	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [writerConversationMessages]);

	return (
		<Box>
			{/* <Heading size={"sm"}>{`Discussion on ${defaultOutlineNodes[selectedNodeId]}`}</Heading> */}
			<Box
				overflow="hidden"
				height="calc(100vh - 500px)"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				{isLoading ? (
					<Box mt={5}>
						<Spinner />
					</Box>
				) : (
					<>
						<VStack spacing={4} align="stretch" overflowY="auto" flex={1}>
							{writerConversationMessages.map((message) => {
								const outlineNodeName = getOutlineNodeById(message.nodeId, outlineNodes)?.name ?? "Deleted Node";

								return (
									<PaperEditorConversationMessage
										key={message.id}
										message={message}
										outlineNodeName={outlineNodeName}
										handleMessageClick={() => setSelectedNodeId(message.nodeId)}
									/>
								);
							})}
							<div ref={messagesEndRef} />
						</VStack>
						<HStack spacing={2} mt={4}>
							<Input
								placeholder="Type your message..."
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && selectedNodeId && handleSendMessage()}
							/>
							<Tooltip
								label="You must select a node id first..."
								isDisabled={!selectedNodeId}
							>
								<Button
									colorScheme="brand"
									onClick={handleSendMessage}
									isDisabled={!selectedNodeId}
								>
									Send
								</Button>
							</Tooltip>
						</HStack>
					</>
				)}
			</Box>
		</Box>
	);
};

export default PaperEditorConversationPanel;
