import { Avatar, Badge, Box, Flex, Heading } from "@chakra-ui/react";
import { WriterConversationMessage } from "../../types/Writer/Conversation";
import { useAuth0 } from "@auth0/auth0-react";
import ConversationMarkdown from "../TaskViewer/ConversationMarkdown";

interface Props {
	message: WriterConversationMessage;
	outlineNodeName: string;
	handleMessageClick: () => void;
}

const PaperEditorConversationMessage = ({ message, outlineNodeName, handleMessageClick }: Props) => {
	const { user } = useAuth0();

	return (
		<Box
			py={message.sender === "user" ? "1" : "2"}
			borderRadius={5}
		>
			<Flex flexDir={'row'} alignItems={"flex-start"} w={"full"}>
				<Flex flexDir={"row"} alignItems={"flex-start"}>
					{message.sender === "user" ? (
						<Avatar name={user.name} src={user.picture} size={"sm"} mr={2} />
					) : (
						<Avatar name="RA profile" src={"/doenba-ico-blue.png"} size={"sm"} mr={2} />
					)}
				</Flex>
				<Box flex={'1 auto'} w={'95%'}>
					<Flex flexDir={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
						mb={2}>
						<Flex
							flexDir={"row"}
							alignItems={"center"}
							h={"32px"}
							justifyContent={"space-between"}
							w={"full"}
						>
							<Heading size="sm">
								{message.sender === "user" ? "You" : "Doenba"}
							</Heading>
						</Flex>
					</Flex>
					<Box
						onClick={handleMessageClick}
						pl={8}
						pr={2}
						mb={4}
						borderLeft={
							message.sender == "user" ? "5px solid #f7ba7c" : "5px solid #0057ec"
						}
					>
						<ConversationMarkdown content={message.message} />
					</Box>
					<Badge colorScheme='purple' variant='outline'>{outlineNodeName}</Badge>
				</Box>
			</Flex>
		</Box>
	);
};

export default PaperEditorConversationMessage;
