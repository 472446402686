import React, { useState } from "react";
import {
	Box,
	Flex,
	Text,
	List,
	ListItem,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import { OutlineNode } from "../../types/Writer/OutlineNode";
import { IoIosMenu } from "react-icons/io";

interface Props {
	isOutlineTreeOpen: boolean;
	nodes: OutlineNode[];
	selectedNodeId: number;
	setNodes: (nodes: OutlineNode[]) => void;
	setSelectedNodeId: (id: number) => void;
}

const OutlineNodeTree: React.FC<Props> = ({ isOutlineTreeOpen, nodes, selectedNodeId, setNodes, setSelectedNodeId }) => {
	const [editing, setEditing] = useState<{
		node: OutlineNode | null;
		parent: OutlineNode | null;
	}>({ node: null, parent: null });

	// Generate a unique ID for a new node using timestamp
	const generateUniqueId = () => Date.now();

	const handleAddChild = (parentNode: OutlineNode) => {
		const newChild = { name: "New Child", id: generateUniqueId() }; // Unique id for new child
		parentNode.children = parentNode.children ? [...parentNode.children, newChild] : [newChild];
		setNodes([...nodes]);
	};

	const handleAddParent = (index: number) => {
		const newParent = { name: "New Parent", id: generateUniqueId(), children: [] }; // Unique id for new parent
		const newNodes = [...nodes];
		newNodes.splice(index + 1, 0, newParent);
		setNodes(newNodes);
	};

	const handleEditNode = (node: OutlineNode, value: string) => {
		node.name = value;
		setNodes([...nodes]);
	};

	const handleNodeClick = (node: OutlineNode) => {
		setSelectedNodeId(node.id);
	};

	const handleNodeDblClick = (node: OutlineNode, parentNode: OutlineNode) => {
		setEditing({ node, parent: parentNode });
	};

	// Function to delete a node
	const handleDeleteNode = (_node: OutlineNode, parentNode: OutlineNode | null, nodeIndex: number) => {
		if (parentNode) {
			// If node has a parent, remove from parent's children
			parentNode.children = parentNode.children?.filter((_, i) => i !== nodeIndex) || [];
		} else {
			// If node is top-level, remove from root nodes list
			const newNodes = nodes.filter((_, i) => i !== nodeIndex);
			setNodes(newNodes);
		}
	};

	const renderTree = (
		nodes: OutlineNode[] | Omit<OutlineNode, "children">[],
		parentNode: OutlineNode | null = null,
		pNodeIndex?: number,
		_isChild?: boolean
	) => {
		return (
			<List spacing={2}>
				{nodes.map((node, index) => (
					<ListItem key={node.id} position="relative">
						{editing.node !== node && (
							<Box
								display="flex"
								alignItems="center"
								justifyContent={'space-between'}
								px={5}
								borderLeft={selectedNodeId === node.id ? '5px solid #0057ec' : null}>
								<Flex flexDir="row"
									as="span"
									p={2}
									pl={isOutlineTreeOpen ? 2 : 8}
									flex="1"
									justifyContent={isOutlineTreeOpen ? "center" : "space-between"}
									cursor="pointer"
									borderRadius={'md'}
									bg={selectedNodeId === node.id ? 'brand.400' : null}
									onClick={() => handleNodeClick(node)}
									onDoubleClick={() => handleNodeDblClick(node, parentNode)}
								>
									{isOutlineTreeOpen ?
										<Text as={!!pNodeIndex ? null : 'b'}
											color={selectedNodeId === node.id ? 'white' : 'gray'}
											fontSize={!!pNodeIndex ? '14px' : '16px'}
										>{!!pNodeIndex && `${pNodeIndex + 1}.`}{index + 1}</Text> :
										<Text as={!!pNodeIndex ? null : 'b'}
											color={selectedNodeId === node.id ? 'white' : 'gray'}
											textTransform={!!pNodeIndex ? null : 'uppercase'}
											fontSize={!!pNodeIndex ? '14px' : '16px'}
											isTruncated
											maxWidth="200px"
											whiteSpace="nowrap"
											overflow="hidden"
											textOverflow="ellipsis"
										>{node.name}</Text>
									}
									{!isOutlineTreeOpen && (
										<Menu size={'sm'}>
											<MenuButton
												as={IconButton}
												icon={<IoIosMenu color={ selectedNodeId === node.id ? 'white' : 'gray' } />}
												size="xs"
												aria-label="Options"
												ml={2}
												variant={'ghost'}
												opacity="0.5"
												_hover={{ opacity: 1 }}
											/>
											<MenuList>
												<MenuItem onClick={() => handleAddChild(node)}>
													Add Child
												</MenuItem>
												<MenuItem onClick={() => handleAddParent(index)}>
													Add Sibling
												</MenuItem>
												<MenuItem onClick={() => handleNodeDblClick(node, parentNode)}>
													Rename
												</MenuItem>
												<MenuItem onClick={() => handleDeleteNode(node, parentNode, index)}>
													Delete
												</MenuItem>
											</MenuList>
										</Menu>
									)}
								</Flex>
							</Box>
						)}
						{editing.node === node && (
							<Input
								autoFocus
								value={node.name}
								onChange={(e) => handleEditNode(node, e.target.value)}
								onBlur={() => setEditing({ node: null, parent: null })}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										setEditing({ node: null, parent: null });
									}
								}}
								size="sm"
								mt={2}
							/>
						)}
						{node.children && <Box pl={4}>{renderTree(node.children, node, index, true)}</Box>}
					</ListItem>
				))}
			</List>
		);
	};

	return <Box>{renderTree(nodes)}</Box>;
};

export default OutlineNodeTree;
