import React from 'react';
import { Box, Text, 
    // Stack, Menu, MenuButton, MenuList, MenuItem, IconButton 
} from '@chakra-ui/react';
import { Outline } from '../../types/Writer/Outline';
// import { formatDate } from '../../utils/dateTimeConversion';
// import { FiMoreVertical } from 'react-icons/fi';

interface OutlineTemplateCardProps {
    outline: Outline;
    showDetail: () => void;
    deleteOutline?: () => void;
    onUseTemplate: () => void;
}

const OutlineTemplateCard: React.FC<OutlineTemplateCardProps> = ({ outline, 
    // deleteOutline, showDetail, 
    onUseTemplate }) => {
    return (
        <Box textAlign={'center'}>
            <Box
                w={'200px'}
                h={'260px'}
                _hover={{ boxShadow: '2px 2px 5px #ccc' }}
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                border={'1px solid #ccc'}
                boxShadow="sm"
                bgImage="url('/paper_preview.png')"
                bgSize="cover"
                bgPosition="center"
                bgRepeat="no-repeat"
                display={'flex'}
                flexDir={'column'}
                justifyContent={'flex-end'}
                cursor={'pointer'}
                onClick={onUseTemplate}
                >
                {/* <Text fontSize="xl" fontWeight="bold">
                    {outline.name}
                </Text>

                <Text fontSize="sm" color={'gray.400'}>
                    {formatDate(outline.createdAt)}
                </Text> */}

                {/* <Stack direction="row" justifyContent="flex-end" mt={5}>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            icon={<FiMoreVertical />}
                            variant="ghost"
                            aria-label="Options"
                        />
                        <MenuList>
                            <MenuItem onClick={showDetail}>Detail</MenuItem>
                            {deleteOutline && (
                                <MenuItem onClick={deleteOutline} color="red">
                                    Delete
                                </MenuItem>
                            )}
                            <MenuItem onClick={onUseTemplate}>Use this template</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack> */}
            </Box>
            <Box mt={2}>
                <Text fontSize={14}>
                {outline.name}
                </Text>
            </Box>
        </Box>
    );
};

export default OutlineTemplateCard;
