import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Flex, Image, Heading, useToast, Textarea, Button,
    useDisclosure, Center,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    IconButton
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { PaperService } from '../services/paperService';
import { ClientError } from '../utils/clientError';
import PaperEditorConversationPanel from '../components/PaperEditorConversationPanel.tsx/PaperEditorConversationPanel';
import { PaperContent } from '../types/Writer/PaperContent';
import { OutlineNode } from '../types/Writer/OutlineNode';
import OutlineNodeTree from '../components/OutlineNodeTree/OutlineNodeTree';
// import wordCount from "word-count";
import { Paper } from '../types/Writer/Paper';
// import { WriterConversationService } from '../services/writerConversationService';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import ParagraphEditor from '../components/PaperEditor/PaperEditorNew';
import { Outline } from '../types/Writer/Outline';
import { RiExpandLeftFill, RiExpandRightFill } from "react-icons/ri";

const WriterEditorWrapper: React.FC = () => {
    const [title, setTitle] = useState('');
    const [keywords, setKeywords] = useState<string>('');
    const [abstract, setAbstract] = useState<string>('');
    const [authors, setAuthors] = useState('');
    const [outline, setOutline] = useState<Outline>(undefined);
    const [outlineNodes, setOutlineNodes] = useState<OutlineNode[]>([]);
    const [paperContent, setPaperContent] = useState<PaperContent>({});
    const [draft, setDraft] = useState<string>('');
    const [resources, setResources] = useState<string>('');
    const [selectedNodeId, setSelectedNodeId] = useState<number | null>(1);
    // const navigate = useNavigate();
    const { paperId } = useParams();
    const toast = useToast();
    const [_isSaving, setIsSaving] = useState<boolean>(false);
    const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
    // const [isAuthorsEditing, setIsAuthorsEditing] = useState<boolean>(false);
    // const [isKeywordsEditing, setIsKeywordsEditing] = useState<boolean>(false);
    // const [isAbstractEditing, setIsAbstractEditing] = useState<boolean>(false);
    const titleInputRef = useRef<HTMLTextAreaElement>(null);
    // const authorsInputRef = useRef<HTMLTextAreaElement>(null);
    // const keywordInputRef = useRef<HTMLTextAreaElement>(null);
    // const abstractInputRef = useRef<HTMLTextAreaElement>(null);
    const [isOutlineTreeOpen, setIsOutlineTreeOpen] = useState<boolean>(true);
    // const [isChatWindowOpen, setIsChatWindowOpen] = useState<boolean>(true);
    const { isOpen: _isEditorOpen = true, onOpen: _onEditorOpen, onClose: _onEditorClose } = useDisclosure({ defaultIsOpen: true });
    // const { isOpen: isGuidanceWindowOpen, onOpen: onGuidanceWindowOpen, onClose: onGuidanceWindowClose } = useDisclosure();
    const [_paper, setPaper] = useState<Paper>(null);
    // const [selectedContext, setSelectedContext] = useState<string>('');

    useEffect(() => {
        async function fetchPaper() {
            try {
                const paper = await PaperService.getPaperById(paperId);
                console.log(paper)
                setPaper(paper);
                setTitle(paper.name);
                setAuthors(paper.authors);
                setPaperContent(paper.content);
                setOutlineNodes(paper.outline.nodes);
                setOutline(paper?.outline);
                setKeywords(paper.keywords);
                setAbstract(paper.abstract);
                setDraft(paper.draft);
                setResources(paper.resources);
            } catch (error) {
                new ClientError(error).toast();
            }
        }
        if (paperId) {
            fetchPaper();
        }
    }, [paperId]);

    // const goBack = () => {
    //     navigate('/editor');
    // }

    const toggleMenuOpenStatus = () => {
        setIsOutlineTreeOpen(!isOutlineTreeOpen);
    }

    const updatePaperNodeContent = (nodeId, content) => {
        setPaperContent({
            ...paperContent,
            [nodeId]: content
        })
    }

    const savePaper = () => {
        setIsSaving(true)
        PaperService.updatePaper(paperId, {
            name: title,
            authors: authors,
            content: paperContent,
            abstract: abstract,
            keywords: keywords,
            draft: draft,
            resources: resources
        }).then(_res => {
            toast({
                title: "Success",
                description: `Paper has been saved.`,
                status: "success",
                duration: 1000,
            })
            setIsSaving(false);
        }).catch(_err => {
            toast({
                title: "File Processing Failed",
                description: `Paper saving failed. Please try again later.`,
                status: "error",
            });
            setIsSaving(false);
        })
    }

    // const onFocusModeClick = () => {
    //     onEditorOpen();
    //     setIsOutlineTreeOpen(false);
    // }

    // const selectedContextRephrase = (selectedText: string) => {
    //     console.log(selectedText);
    //     // WriterConversationService.addToWriterConversation(
    //     //     paperId,
    //     //     selectedNodeId,
    //     //     `Please rephrase this sentence: `,

    //     // )
    // }

    return (
        <Flex flexDir={'row'} w={'full'} h={'full'}>
            <Flex h={'100%'} flexDir={'column'} w={isOutlineTreeOpen ? '100px' : '350px'} justifyContent={'space-between'} boxShadow={'md'} position={'relative'}>
                <Box h={'full'}>
                    <Center p={5}>
                        <Box w={isOutlineTreeOpen ? '30px' : '150px'} h={'50px'}>
                            <Image
                                objectFit='cover'
                                src={isOutlineTreeOpen ? '/doenba-ico-blue.png' : '/doenba-logo-blue.png'}
                                alt='Doenba'
                            />
                        </Box>
                    </Center>
                    <Box mt={5} h={'calc(100% - 460px)'} overflow={'auto'} >
                        <OutlineNodeTree
                            isOutlineTreeOpen={isOutlineTreeOpen}
                            nodes={outlineNodes}
                            selectedNodeId={selectedNodeId}
                            setNodes={setOutlineNodes}
                            setSelectedNodeId={setSelectedNodeId}
                        />
                    </Box>
                    <Box>{outline?.name}</Box>
                    <Flex flexDir={'column'} p={5} pb={12}>
                        <Button mb={2}>Overview</Button>
                        <Button mb={2}>Style</Button>
                        <Button mb={2}>Export</Button>
                        <Button mb={2} variant={'ghost'}>Tutorials</Button>
                        <Button variant={'ghost'}>Helps</Button>
                    </Flex>
                </Box>
                <Box position={'absolute'} top={'110px'} right={'-10px'} bg={'white'} >
                    <IconButton size={'xs'} variant={'outline'} icon={isOutlineTreeOpen ? <RiExpandRightFill /> : <RiExpandLeftFill />} aria-label='Collapse' onClick={toggleMenuOpenStatus} />
                </Box>
            </Flex>
            <Box flex={'1 auto'} h={'100vh'} px={5} pb={5}>
                <Box w={'full'} textAlign={'left'} my={5} h={'50px'}>
                    {isTitleEditing ? (
                        <Textarea
                            ref={titleInputRef}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onBlur={() => {
                                setIsTitleEditing(false);
                                savePaper();
                            }}
                            autoFocus
                            style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: 36, fontWeight: 600 }}
                            sx={{
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                            }}
                            _focus={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                            _hover={{
                                border: 'none',
                            }}
                        />
                    ) : (
                        <Heading onClick={() => setIsTitleEditing(true)}
                            style={{ fontFamily: '"Times New Roman", Times, serif' }}
                        >{title !== '' ? title : 'Untitled'}</Heading>
                    )}
                </Box>
                <Box flex={'1'} h={'90%'} w='full' overflow={'auto'}>
                    {isTitleEditing ? (
                        <Textarea
                            ref={titleInputRef}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onBlur={() => {
                                setIsTitleEditing(false);
                                savePaper();
                            }}
                            autoFocus
                            style={{ fontFamily: '"Times New Roman", Times, serif', fontSize: 18, fontWeight: 600 }}
                            sx={{
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                            }}
                            _focus={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                            _hover={{
                                border: 'none',
                            }}
                        />
                    ) : (
                        <Heading as='h4' size='md' onClick={() => setIsTitleEditing(true)}
                            style={{ fontFamily: '"Times New Roman", Times, serif' }}
                        >{title !== '' ? title : 'Untitled'}</Heading>
                    )}
                    <ParagraphEditor
                        nodeContent={paperContent[selectedNodeId]}
                        updatePaperNodeContent={(content) => updatePaperNodeContent(selectedNodeId, content)}
                        handleInputFocus={() => setSelectedNodeId(selectedNodeId)}
                        savePaper={savePaper}
                    // selectedContextRephrase={selectedContextRephrase}
                    />
                    {/* <MathJax>we have we have we have we have {"\\(\\frac{10}{4x} \\approx 2^{12}\\)"} we have we have we have we have </MathJax>
                    <MathJax>... we have `\(x_1 = 132\)` and `\(x_2 = 370\)` and so ...</MathJax> */}
                </Box>
                <Center w={'full'}>
                    <Flex flexDir={'row'} h={'50px'} justifyContent={'space-between'} alignItems={'center'} boxShadow={'md'}>
                        <Flex flexDir={'row'} alignItems={'center'} px={5} mr={2}>
                            <Box mr={2}>
                                <AiOutlineThunderbolt />
                            </Box>
                            AI Tools
                        </Flex>
                        <Button mr={2} variant={'outline'}>Rephrase</Button>
                        <Button mr={2} variant={'outline'}>Suggestions</Button>
                        <Button mr={2} variant={'outline'}>Reorgnize</Button>
                    </Flex>
                </Center>
            </Box>
            <Flex flexDir={'column'} maxW={'550px'} minW={'550px'} h={'full'} boxShadow={'md'}>
                <Flex flexDir={'row'} p={2} justifyContent={'flex-end'}>
                    <Box mr={2}>
                        <Button size={'xs'}>History</Button>
                    </Box>
                    <Box>
                        <Button size={'xs'}>Export</Button>
                    </Box>
                </Flex>
                <Accordion defaultIndex={[1]} allowMultiple>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box as='span' flex='1' textAlign='left'>
                                    Resources
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Box>{JSON.stringify(resources)}</Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <Box w={'full'} h={'full'} py={5} px={2}>
                    <PaperEditorConversationPanel
                        paperId={paperId}
                        selectedNodeId={selectedNodeId}
                        setSelectedNodeId={setSelectedNodeId}
                        outlineNodes={outlineNodes}
                    />
                </Box>
            </Flex>
        </Flex>
    );
};

export default WriterEditorWrapper;

