// import { Search2Icon } from '@chakra-ui/icons';
import { Tabs, TabList, Tab, Link as ChakraLink, Tooltip, Icon } from '@chakra-ui/react';
import { GiArchiveResearch } from "react-icons/gi";
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Search2Icon } from '@chakra-ui/icons';


interface OperationWindowTabComponentProps {
    isMobile: boolean;
}

const OperationWindowTabComponent = ({ isMobile }: OperationWindowTabComponentProps) => {
    const location = useLocation();
    const isCurrentPathMyDrive = location.pathname === "/my-library";
    const { t } = useTranslation();

    const tabs = [
        { 
            link: '/search', 
            label: 'mini-nav-tasksearch', 
            description: 'mini-nav-tasksearch-description',
            mobileIcon: Search2Icon
        },
        { 
            link: '/reader', 
            label: 'mini-nav-taskrqa', 
            description: 'mini-nav-taskrqa-description',
            mobileIcon: GiArchiveResearch
        },
        // { 
        //     link: '/writer', 
        //     label: 'mini-nav-writer', 
        //     description: 'mini-nav-writer-description',
        //     mobileIcon: GiArchiveResearch
        // },
        // {
        //     link: '/reader/notes',
        //     label: 'mini-nav-notes',
        //     description: 'mini-nav-notes-description',
        //     mobileIcon: GiArchiveResearch
        // }
    ]

    const matchingTabIndex = tabs.findIndex(tab => matchPath(tab.link, location.pathname));

    return (
        <Tabs variant="unstyled" size={'sm'} index={matchingTabIndex}>
            { isCurrentPathMyDrive &&
                <TabList>
                    {tabs.map(tab => (
                        <Tab key={tab.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                            <ChakraLink as={RouterLink} to={tab.link}>
                                <Tooltip label={t(tab.description)} placement='bottom'>
                                    {t(tab.label)}
                                </Tooltip>
                            </ChakraLink>
                        </Tab>
                    ))}
                </TabList>
            }
            { !isCurrentPathMyDrive &&
                <TabList>
                    {!isMobile && tabs.map(tab => (
                        <Tab key={tab.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                            <ChakraLink as={RouterLink} to={tab.link}>
                                <Tooltip label={t(tab.description)} placement='bottom'>
                                    {t(tab.label)}
                                </Tooltip>
                            </ChakraLink>
                        </Tab>
                    ))}
                    {isMobile && tabs.map(tab => (
                        <Tab key={tab.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                            <ChakraLink as={RouterLink} to={tab.link} pb={0}>
                                <Tooltip label={t(tab.description)} placement='bottom'>
                                    <span>
                                        <Icon as={tab.mobileIcon} />
                                    </span>
                                </Tooltip>
                            </ChakraLink>
                        </Tab>
                    ))}
                </TabList>
            }
        </Tabs>
    );
}

export default OperationWindowTabComponent;