import { OutlineNode } from "../types/Writer/OutlineNode";

/**
 * Get an outline node by its id
 * @param nodeId The id of the node to find
 * @param nodes The list of nodes to search
 */
export function getOutlineNodeById(nodeId: number, nodes: OutlineNode[] | Omit<OutlineNode, "children">[]): OutlineNode | Omit<OutlineNode, "children"> | undefined {
	for (const node of nodes) {
		if (node.id === nodeId) {
			return node;
		}

		if ("children" in node) {
			const result = getOutlineNodeById(nodeId, node.children);
            if (result !== undefined) {
                return result;
            }
		}
	}

	return undefined;
}