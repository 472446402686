import {
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	IconButton,
	MenuItemOption,
} from "@chakra-ui/react";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { UserDataService } from "../../services/userDataService";

type NativeNameType = {
	nativeName: string;
};

type LanguageObjectType = {
	en: NativeNameType;
	cn: NativeNameType;
};

const languages: LanguageObjectType = {
	en: { nativeName: "English" },
	cn: { nativeName: "中文" },
};

const LangSelect = () => {
	const { i18n } = useTranslation();
	const{ user } = useAuth0();

	async function handleChange(lang: string) {
		i18n.changeLanguage(lang);
		UserDataService.setLanguageLocal(lang);
		const userId = user?.sub;
		if (user) {
			await UserDataService.updateUserDataLang(userId, { lang });
		}
	}

	return (
		<Menu closeOnSelect={false}>
			<MenuButton as={IconButton} size="sm" icon={<MdLanguage />} bg='gray.200' />
			<MenuList width="100px" zIndex={3}>
				<MenuOptionGroup
					type="radio"
					defaultValue={i18n.language}
					onChange={(lng) => handleChange(lng as string)}
					value={i18n.language}
				>
					{Object.keys(languages).map((lng) => (
						<MenuItemOption key={lng} value={lng}>
							{languages[lng as keyof LanguageObjectType].nativeName}
						</MenuItemOption>
					))}
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
};

export default LangSelect;
