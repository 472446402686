import { Box, Flex, Image, Tooltip, useBreakpointValue, Button, Icon, Link as ChakraLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Search2Icon } from "@chakra-ui/icons";
import { GiArchiveResearch } from "react-icons/gi";
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { LuPenTool } from "react-icons/lu";

const SideNav = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const hideQuickUploader = location.pathname.includes("/my-library") || location.pathname.includes("/about") || location.pathname.includes('/editor');
    const isMobile = useBreakpointValue({ base: true, md: true, lg: true, xl: false });
    const isCurrentPathMyDrive = location.pathname === "/my-library";

    const menus = [
        {
            link: '/search',
            label: 'mini-nav-tasksearch',
            description: 'mini-nav-tasksearch-description',
            mobileIcon: Search2Icon
        },
        {
            link: '/reader',
            label: 'mini-nav-taskrqa',
            description: 'mini-nav-taskrqa-description',
            mobileIcon: GiArchiveResearch
        },
        {
            link: '/editor',
            label: 'mini-nav-editor',
            description: 'mini-nav-editor-description',
            mobileIcon: LuPenTool
        }
        // {
        //     link: '/reader/notes',
        //     label: 'mini-nav-notes',
        //     description: 'mini-nav-notes-description',
        //     mobileIcon: GiArchiveResearch
        // }
    ]

    const matchingTabIndex = menus.findIndex(tab => matchPath(tab.link, location.pathname));

    return (
        <>
            <Flex flexDir={'column'} minW={isMobile ? '60px' : '280px'} justifyContent={'space-between'} h={'full'} p={5} pr={0}>
                <Flex flexDir={'column'}>
                    {hideQuickUploader &&
                        <Box>
                            <Image
                                w={'105px'}
                                objectFit='cover'
                                src='/doenba-logo-blue.png'
                                alt='Doenba'
                            />
                        </Box>
                    }
                    <Box mt={12}>
                        {isCurrentPathMyDrive &&
                            <Flex flexDir='column'>
                                {menus.map((menu, index) => (
                                    <Button mb={2} colorScheme="blue" key={menu.link} variant={matchingTabIndex === index ? 'solid' : 'ghost'}>
                                        <ChakraLink as={RouterLink} to={menu.link}>
                                            <Tooltip label={t(menu.description)} placement='bottom'>
                                                {t(menu.label)}
                                            </Tooltip>
                                        </ChakraLink>
                                    </Button>
                                ))}
                            </Flex>
                        }
                        {!isCurrentPathMyDrive &&
                            <Flex flexDir='column'>
                                {!isMobile && menus.map((menu, index) => (
                                    <Button mb={2} size={'sm'} colorScheme={matchingTabIndex === index ? 'brand' : 'none'} variant={matchingTabIndex === index ? 'solid' : 'ghost'} key={menu.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                                        <ChakraLink as={RouterLink} to={menu.link}>
                                            <Tooltip label={t(menu.description)} placement='bottom'>
                                                {t(menu.label)}
                                            </Tooltip>
                                        </ChakraLink>
                                    </Button>
                                ))}
                                {isMobile && menus.map((menu, index) => (
                                    <Button mb={2} colorScheme="blue" variant={matchingTabIndex === index ? 'solid' : 'ghost'} key={menu.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                                        <ChakraLink as={RouterLink} to={menu.link} pb={0}>
                                            <Tooltip label={t(menu.description)} placement='bottom'>
                                                <span>
                                                    <Icon as={menu.mobileIcon} />
                                                </span>
                                            </Tooltip>
                                        </ChakraLink>
                                    </Button>
                                ))}
                            </Flex>
                        }
                    </Box>
                </Flex>
            </Flex>
        </>
    );
}

export default SideNav;