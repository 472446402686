import axios from "axios";
import { BASE_URL } from ".";
import { UpdatePaperRequest, CreatePaperRequest, Paper } from "../types/Writer/Paper";


export class PaperService {
    public static async getPapers() {
		const response = await axios.get<Paper[]>(BASE_URL + "/paper");
		return response.data;
	}

	public static async getPaperById(paperId: string) {
		const response = await axios.get<Paper>(BASE_URL + "/paper/" + paperId);
		return response.data;
	}

	public static async createPaper(data: CreatePaperRequest) {
		const response = await axios.post<Paper>(BASE_URL + "/paper", data);
		return response.data;
	}

	public static async updatePaper(paperId: string, newPaper: UpdatePaperRequest) {
		const response = await axios.put<Paper>(BASE_URL + "/paper/" + paperId, newPaper);
		return response.data;
	}

	public static async deletePaper(paperId: string): Promise<void> {
		await axios.delete(BASE_URL + "/paper/" + paperId);
	}
}