import axios from "axios";
import { BASE_URL } from ".";
import { OutlineNode } from "../types/Writer/OutlineNode";


export class OutlineService {
    public static async getOutlines(outlineId?: string) {
		const response = await axios.get(BASE_URL + "/outline" + (outlineId ? `/${outlineId}` : ""));
		return response.data;
	}

	public static async createOutline(data: any) {
		const response = await axios.post<OutlineNode>(BASE_URL + "/outline/", data);
		return response.data;
	}

	public static async updateOutline(outlineId: string, newOutline: OutlineNode[]) {
		const response = await axios.put<OutlineNode[]>(BASE_URL + "/outline/" + outlineId, {
			name: 'outline dummy name',
			nodes: newOutline
		});
		return response.data;
	}

	public static async deleteOutline(outlineId: string): Promise<void> {
		await axios.delete(BASE_URL + "/outline/" + outlineId);
	}

	public static async extractOutline(paper: File): Promise<any> {
		const formData =  new FormData();
		formData.append("file", paper);
		const response = await axios.post(BASE_URL + '/outline/extract', formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		})
		return response.data
	}
}